<template>
  <v-container>
    <v-row>
      <v-col
        cols="12"
        :md="showHelp ? 8 : 11"
        :sm="12"
        v-show="!showExpand"
        class="pr-0"
      >
        <GoBackBtn :routeToGo="routeToGo" :tabId="false" />
        <PageHeader :title="title" />
        <v-data-table
          :headers="headers"
          :items="configDependencias"
          class="elevation-1"
          :search="search"
        >
          <template v-slot:top>
            <v-toolbar flat>
              <v-row>
                <v-col cols="10">
                  <v-text-field
                    v-model="search"
                    :append-icon="searchIcon"
                    label="Buscar"
                    single-line
                    hide-details
                  ></v-text-field>
                </v-col>
                <v-col cols="2" align="end" v-if="canCreate">
                  <v-btn color="primary" @click="openModal()" class="to-right">
                    Nuevo
                  </v-btn>
                </v-col>
              </v-row>
            </v-toolbar>
          </template>
          <template v-slot:[`item.actions`]="{ item }">
            <v-tooltip left v-if="canEdit">
              <template v-slot:activator="{ on, attrs }">
                <v-icon
                  v-bind="attrs"
                  size="20"
                  v-on="on"
                  @click="openModal(item)"
                >
                  {{ editIcon }}
                </v-icon>
              </template>
              <span>Editar dependencia</span>
            </v-tooltip>
            <v-tooltip left v-if="canDelete">
              <template v-slot:activator="{ on, attrs }">
                <v-icon
                  v-bind="attrs"
                  size="20"
                  v-on="on"
                  @click="deleteDependencia(item.depBenId)"
                >
                  {{ deleteIcon }}
                </v-icon>
              </template>
              <span>Eliminar dependencia</span>
            </v-tooltip>
          </template>
        </v-data-table>
      </v-col>
      <!-- Texto de ayuda principal -->
      <v-col
        cols="12"
        :md="!showHelp ? 1 : showExpand ? 12 : 4"
        class="to-right pt-6 px-0"
        v-if="showIcon"
      >
        <Ayuda
          :optionCode="optionCode"
          :pantallaTitle="title"
          @toggleExpand="showExpand = !showExpand"
          @toggleHelp="toggleHelp"
          @toggleIcon="toggleIcon()"
        />
      </v-col>
    </v-row>
    <v-dialog
      v-if="openModalEdit"
      v-model="openModalEdit"
      max-width="500px"
      @keydown.esc="closeModal"
      persistent
    >
      <EditDependencia
        :tipoDependenciaObject="tipoDependenciaObject"
        @closeAndReload="closeAndReload"
      ></EditDependencia>
    </v-dialog>
    <DeleteDialog
      :titleProp="titleDelete"
      :openDelete.sync="showDeleteModal"
      @onDeleteItem="confirmDelete()"
      :maxWidth="'25%'"
    />
  </v-container>
</template>

<script>
import PageHeader from "@/components/ui/PageHeader";
import { mapActions } from "vuex";
import rules from "@/utils/helpers/rules";
import enums from "@/utils/enums/index.js";
import DeleteDialog from "@/components/shared/DeleteDialog";
import EditDependencia from "@/components/modules/afiliaciones/afiliados/configuracion/EditDependencia.vue";
import GoBackBtn from "@/components/shared/GoBackBtn";
import Ayuda from "@/components/shared/Ayuda.vue";

export default {
  name: "Dependencias",
  components: { PageHeader, DeleteDialog, EditDependencia, GoBackBtn, Ayuda },
  data: () => ({
    editIcon: enums.icons.EDIT,
    deleteIcon: enums.icons.DELETE,
    showDeleteModal: false,
    search: "",
    searchIcon: enums.icons.SEARCH,
    showExpand: false,
    showHelp: false,
    showIcon: true,
    optionCode: enums.webSiteOptions.DEPENDENCIAS,
    idToDelete: null,
    formEditTitle: "",
    routeToGo: "TablasAfiliados",
    title: enums.titles.DEPENDENCIAS,
    titleDelete: enums.titles.DELETE_DEPENDENCIA,
    rules: rules,
    configDependencias: [],
    tipoDependenciaObject: {},
    headers: [
      {
        text: "Código",
        align: "start",
        value: "depBenId"
      },
      {
        text: "Nombre",
        value: "depBenNom"
      },
      {
        text: "Acciones",
        value: "actions",
        descripcion: "",
        sortable: false,
        align: "end"
      }
    ],
    allowedActions: null,
    canEdit: false,
    canDelete: false,
    canCreate: false,
    openModalEdit: false
  }),
  created() {
    this.$store.dispatch("user/changeAppLoading", {
      status: false,
      text: "",
      opaque: false
    });
    this.loadDependencias();
  },
  mounted() {
    this.allowedActions = JSON.parse(localStorage.getItem("allowedActions"));
    this.setPermisos();
  },
  methods: {
    ...mapActions({
      getConfiguracionTiposDependencias: "configAfiliaciones/getDependencias",
      deleteConfiguracionTipoDependencia:
        "configAfiliaciones/deleteDependencia",
      setAlert: "user/setAlert"
    }),
    setPermisos() {
      this.allowedActions?.forEach(x => {
        switch (x.optionCode) {
          case enums.modules.adminSistema.permissions.ELIMINAR_DEPENDENCIA:
            this.canDelete = true;
            break;
          case enums.modules.adminSistema.permissions.EDITAR_DEPENDENCIA:
            this.canEdit = true;
            break;
          case enums.modules.adminSistema.permissions.NUEVA_DEPENDENCIA:
            this.canCreate = true;
            break;
          default:
            break;
        }
      });
    },
    async loadDependencias() {
      const response = await this.getConfiguracionTiposDependencias();
      this.configDependencias = response;
    },
    deleteDependencia(id) {
      this.showDeleteModal = true;
      this.idToDelete = id;
    },
    async confirmDelete() {
      const response = await this.deleteConfiguracionTipoDependencia({
        depBenId: this.idToDelete
      });
      if (response.status === 200) {
        this.showDeleteModal = false;
        this.setAlert({ type: "success", message: "Eliminado con éxito." });
        this.loadDependencias();
      }
    },
    openModal(item) {
      this.openModalEdit = true;
      this.tipoDependenciaObject = item;
    },
    closeModal() {
      this.openModalEdit = false;
    },
    closeAndReload() {
      this.openModalEdit = false;
      this.loadDependencias();
    },
    toggleHelp() {
      this.showHelp = !this.showHelp;
      this.showHelp ? [] : (this.showExpand = false);
    },
    toggleIcon() {
      this.showIcon = true;
    }
  }
};
</script>

<style lang="scss" scoped></style>
