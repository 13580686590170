<template>
  <v-card>
    <v-container>
      <v-card-title>
        <span class="pl-1 primary--text">{{ formEditTitle }}</span>
      </v-card-title>

      <v-card-text>
        <v-form
          v-model="isFormValid"
          ref="form"
          form="form"
          id="form"
          @submit.prevent="save()"
        >
          <v-row>
            <v-col cols="12" md="4" class="pb-0">
              <v-text-field
                v-model="codigo"
                label="Código"
                dense
                outlined
                :disabled="!isNew"
                :filled="!isNew"
                :readonly="!isNew"
                class="theme--light.v-input input"
                :rules="rules.required.concat(rules.positiveNumberHigher0)"
                autocomplete="off"
              ></v-text-field>
            </v-col>
            <v-col cols="12" md="8" class="pb-0">
              <v-text-field
                v-model.trim="nombre"
                label="Nombre"
                dense
                outlined
                :rules="
                  rules.required.concat([
                    rules.requiredTrim(nombre),
                    rules.maxLength(nombre, 50)
                  ])
                "
              ></v-text-field>
            </v-col>
          </v-row>
        </v-form>
      </v-card-text>

      <v-card-actions>
        <v-spacer></v-spacer>
        <v-btn outlined @click="closeModal"> Cancelar </v-btn>
        <v-btn
          type="submit"
          :disabled="!isFormValid"
          form="form"
          color="primary"
        >
          Guardar
        </v-btn>
      </v-card-actions>
    </v-container>
  </v-card>
</template>

<script>
import { mapActions } from "vuex";
import rules from "@/utils/helpers/rules";
import enums from "@/utils/enums/index.js";

export default {
  name: "EditDependencia",
  props: {
    tipoDependenciaObject: {
      type: Object,
      default: null
    }
  },
  data: () => ({
    isFormValid: false,
    formEditTitle: enums.titles.EDIT_DEPENDENCIA,
    formNewTitle: enums.titles.NUEVA_DEPENDENCIA,
    rules: rules,
    codigo: null,
    nombre: null,
    isNew: false
  }),
  created() {
    if (this.tipoDependenciaObject) {
      this.setTipoDependencia();
    } else {
      this.isNew = true;
      this.newTipoDependencia();
    }
  },
  methods: {
    ...mapActions({
      postTipoDependencia: "configAfiliaciones/postDependencia",
      setAlert: "user/setAlert"
    }),
    async newTipoDependencia() {
      this.formEditTitle = this.formNewTitle;
    },
    setTipoDependencia() {
      this.nombre = this.tipoDependenciaObject.depBenNom;
      this.codigo = this.tipoDependenciaObject.depBenId;
    },
    async save() {
      let data = {
        depBenId: this.isNew
          ? this.codigo
          : this.tipoDependenciaObject.depBenId,
        depBenNom: this.nombre,
        isNew: this.isNew
      };
      const res = await this.postTipoDependencia(data);
      if (res.status === 200) {
        this.setAlert({ type: "success", message: "Guardado con éxito" });
      }
      this.closeModal();
    },
    closeModal() {
      this.$emit("closeAndReload");
    }
  }
};
</script>

<style scoped>
::v-deep .theme--light.v-input input {
  color: rgb(56, 56, 56);
}

::v-deep .v-select__selection,
.v-select__selection--comma,
.v-select__selection--disabled input {
  color: black !important;
}

::v-deep .v-input__slot {
  cursor: context-menu !important;
}

::v-deep label {
  margin-bottom: 0;
}
</style>
